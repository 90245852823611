import { Button, Typography, useMediaQuery } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import MainLayout from "../components/MainLayout"
import "../scss/index.scss"


export default function IndexPage() {
  return (
    <MainLayout>
      <div className="home-container">
        <Typography variant={useMediaQuery('(min-width:700px)') ? "h1" : "h4"}>Conspiracy4you</Typography>
        <Button size="large" variant="contained" color="secondary" component={Link} to="/quiz">
          Take the Quiz
        </Button>
      </div>
    </MainLayout>
  )
}